<template>
	<HelloWorld />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';

export default {
	name: 'App',
	components: {
		HelloWorld,
	},
	mounted() {
		document.title = 'Superhelt AS';
	},
};
</script>

<style>
body {
	margin: 0;
	padding: 0;
	background-color: skyblue;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
