<template>
	<div class="hello">
		<div class="cloud-col">
			<div class="cloud cloud1"></div>
		</div>
		<div class="cloud-col">
			<div class="cloud cloud2"></div>
		</div>
		<div class="cloud-col">
			<div class="cloud cloud3"></div>
		</div>
	</div>
	<div class="hero">
		<img src="../assets/hero.svg" />
	</div>
	<div class="logo">
		<div class="name">
			Superhelt
		</div>
		<div class="slogan">
			Business i skyen
		</div>
	</div>
</template>

<script>
export default {
	name: 'HelloWorld',
};
</script>

<style>
@font-face {
	font-family: 'Indiana Jonas Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Indiana Jonas Regular'), url('../assets/indiana-jonas-font/Indiana-Jonas-48p.woff') format('woff');
}

.hello {
	display: grid;
	height: 100vh;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	overflow: hidden;
}

.cloud-col {
	position: relative;
}

.cloud {
	background: #fff;
	background: linear-gradient(top, #fff 5%, #f1f1f1 100%);

	border-radius: 100px;

	box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);

	height: 120px;
	position: absolute;
	width: 350px;
	left: 50%;
}

.cloud:after,
.cloud:before {
	background: #fff;
	content: '';
	position: absolute;
	z-index: -1;
}

.cloud:after {
	border-radius: 100px;

	height: 100px;
	left: 50px;
	top: -50px;
	width: 100px;
}

.cloud:before {
	border-radius: 200px;

	width: 180px;
	height: 180px;
	right: 50px;
	top: -90px;
}

.cloud1 {
	animation: animateCloud1;
	animation-duration: 20s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform: scale(0.65) translateX(-50%);
}

.cloud2 {
	animation: animateCloud2;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform: translateX(-50%);
}

.cloud3 {
	animation: animateCloud3;
	animation-duration: 14s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform: scale(0.85) translateX(-50%);
}

.hero {
	position: absolute;
	bottom: 20vh;
	left: 10vw;
}

.hero img {
	height: 60vh;
	animation: animateHero;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.logo {
	position: absolute;
	right: 10vw;
	top: 10vh;
}

.logo .name {
	font-size: 10vw;
	font-family: 'Indiana Jonas Regular';
}
.logo .slogan {
	margin-left: 7vw;
	margin-top: -2.5vw;
	font-size: 2vw;
}

/* KEYFRAMES */

@keyframes animateCloud1 {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 110vh;
	}
}

@keyframes animateCloud2 {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 110vh;
	}
}

@keyframes animateCloud3 {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 110vh;
	}
}

@keyframes animateHero {
	0% {
		margin-left: 0px;
	}
	20% {
		margin-left: 15px;
	}
	40% {
		margin-left: 0px;
	}
	60% {
		margin-left: 30px;
	}
	100% {
		margin-top: 0px;
	}
}
</style>
